import MoveSign from './ScriptTypes/MoveSign';
import SignDisplayOrder from './ScriptTypes/SignDisplayOrder';
import SaveTemplateEvents from './ScriptTypes/SaveTemplateEvents';
import QueryMap from './ScriptTypes/QueryMap';
import CopySignSupport from './ScriptTypes/CopySignSupport';
import CopyProject from './ScriptTypes/CopyProject';
import TogglePayPeriodLock from './ScriptTypes/TogglePayPeriodLock';
import NewSignSaveData from './ScriptTypes/NewSignSaveData';
import NewContactSaveData from './ScriptTypes/UB_NewContactSaveData';
import GetParcelImage from './ScriptTypes/UB_GetParcelImage';
import MoveEvent from './ScriptTypes/MoveEvent';
import GroupingNewEntity from './ScriptTypes/AM_GroupingNewEntity';
import GroupingCancel from './ScriptTypes/AM_GroupingCancel';
import GroupingSave from './ScriptTypes/AM_GroupingSave';
import RecurNewEvent from './ScriptTypes/RecurNewEvent';
import SubmitJournal from './ScriptTypes/UB_SubmitJournal';
import JournalReadOnlyCheck from './ScriptTypes/UB_JournalReadonlyCheck';
import CashPaymentAmount from './ScriptTypes/UB_CashPaymentAmount';
import PaymentSaved from './ScriptTypes/UB_PaymentSaved';
import UB_Cycle_Test from './ScriptTypes/UB_Cycle_Test';
import UB_Cycle_Mail from './ScriptTypes/UB_Cycle_Mail';
import UB_Cycle_Bill from './ScriptTypes/UB_Cycle_Bill';
import UB_Cycle_Itron_Import from './ScriptTypes/UB_Cycle_Itron_Import';
import UB_Cycle_Itron_Export from './ScriptTypes/UB_Cycle_Itron_Export';
import AuditRecordModal from './ScriptTypes/AuditRecordModal';
import PracticeEventParent from './ScriptTypes/PI_PracticeEventParent';
import UBMeterExchange from './ScriptTypes/UB_MeterExchange';
import E311_RequiredProps from './ScriptTypes/E311_RequiredProps';
import UB_Title_NewFinal from './ScriptTypes/UB_Title_NewFinal';
import UB_Title_Submit from './ScriptTypes/UB_Title_Submit';
import UB_ServicePrevRead from './ScriptTypes/UB_ServicePrevRead';
import WG_LoadContactOrg from './ScriptTypes/WG_LoadContactOrg';
import Ohio_DefaultBMPTypeStormWater from './ScriptTypes/Ohio_DefaultBMPTypeStormWater';
import MySalesUserInfoSubmit from './ScriptTypes/MySalesUserInfoSubmit';
import UB_AddressCheck from './ScriptTypes/UB_AddressCheck';
import AM_PartConfigurationSave from './ScriptTypes/AM_PartConfigurationSave';
import AM_PartConfigurationCheckAll from './ScriptTypes/AM_PartConfigurationCheckAll';
import UB_CalculateRoutes from './ScriptTypes/UB_CalculateRoutes';
import MV_NewCatalogRow from './ScriptTypes/MV_NewCatalogRow';
import MV_MapSnapshot from './ScriptTypes/MV_MapSnapshot';
import ADMIN_LookupDisplayOrder from './ScriptTypes/ADMIN_LookupDisplayOrder';
import AM_CrewMgt from './ScriptTypes/AM_CrewMgt';
import MV_SuperAdminFilter from './ScriptTypes/MV_SuperAdminFilter';
import MV_SuperAdminCopyCatalog from './ScriptTypes/MV_SuperAdminCopyCatalog';
import MV_GetCost from './ScriptTypes/MV_GetCost';
import MV_SuperAdminNewContractor from './ScriptTypes/MV_SuperAdminNewContractor';
import MV_LinkToContractorSite from './ScriptTypes/MV_LinkToContractorSite';
import DBO_PrintTemplate from './ScriptTypes/DBO_PrintTemplate';
import MySalesmanLibrarySelected from './ScriptTypes/MySalesmanLibrarySelected';
import Ohio_CopyEvent from './ScriptTypes/Ohio_CopyEvent';
import MV_DeleteCatalogItem from './ScriptTypes/MV_DeleteCatalogItem';
import MV_SendTwilioCode from './ScriptTypes/MV_SendTwilioCode';
import MV_GetConsumer from './ScriptTypes/MV_GetConsumer';
import NE_LincolnParks_CopyTree from './ScriptTypes/NE_LincolnParks_CopyTree';
import ReloadPage from './ScriptTypes/ReloadPage';
import MV_PrintableEmail from './ScriptTypes/MV_PrintableEmail/MV_PrintableEmail';
import MV_SendEmails from './ScriptTypes/MV_SendEmails';
import MV_PWANewCatalogRow from './ScriptTypes/MV_PWANewCatalogRow';
import MV_PWRNewCatalogRow from './ScriptTypes/MV_PWRNewCatalogRow';
import MV_PWVNewCatalogRow from './ScriptTypes/MV_PWVNewCatalogRow';
import UB_CheckTotalFinalsForDate from './ScriptTypes/UB_CheckTotalFinalsForDate';
import MV_NewContact from './ScriptTypes/MV_NewContact';
import MV_DeleteContact from './ScriptTypes/MV_DeleteContact';
import MV_DeckNewCatalogRow from './ScriptTypes/MV_DeckNewCatalogRow';
import MV_DeckStainNewCatalogRow from './ScriptTypes/MV_DeckStainNewCatalogRow';
import MV_FencePricingRules from './ScriptTypes/MV_FencePricingRules';
import MV_DeckPricingRules from './ScriptTypes/MV_DeckPricingRules';
import MV_DeckStainPricingRules from './ScriptTypes/MV_DeckStainPricingRules';
import MV_PWAPricingRules from './ScriptTypes/MV_PWAPricingRules';
import MV_PWRPricingRules from './ScriptTypes/MV_PWRPricingRules';
import MV_PWVPricingRules from './ScriptTypes/MV_PWVPricingRules';
import MV_ArcSite from './ScriptTypes/MV_ArcSite';
import UB_Admin_GroupClear from './ScriptTypes/UB_Admin_GroupClear';
import UB_Admin_GroupSave from './ScriptTypes/UB_Admin_GroupSave';
import MV_DeleteConsumer from './ScriptTypes/MV_DeleteConsumer';
import UB_SewerRebase from './ScriptTypes/UB_Rebase'
import UB_BillingEvent from './ScriptTypes/UB_BillingEvent';
import MV_RoofNewCatalogRow from './ScriptTypes/MV_RoofNewCatalogRow';
import MV_RoofPricingRules from './ScriptTypes/MV_RoofPricingRules';
import OH_Fund_Validation from './ScriptTypes/OH_Fund_Validation';
import CopyMarkingSupport from './ScriptTypes/CopyMarkingSupport';
import AM_DeleteUser from './ScriptTypes/AM_DeleteUser';
import AM_TaskListFilter from './ScriptTypes/AM_TaskListFilter';
import AM_TaskListFilterClear from './ScriptTypes/AM_TaskListFilterClear';

export default {
  MoveSign: MoveSign,
  SignDisplayOrder: SignDisplayOrder,
  SaveTemplateEvents: SaveTemplateEvents,
  QueryMap: QueryMap,
  CopySignSupport: CopySignSupport,
  CopyProject: CopyProject,
  TogglePayPeriodLock: TogglePayPeriodLock,
  NewSignSaveData: NewSignSaveData,
  NewContactSaveData: NewContactSaveData,
  GetParcelImage: GetParcelImage,
  MoveEvent: MoveEvent,
  GroupingNewEntity: GroupingNewEntity,
  GroupingCancel: GroupingCancel,
  GroupingSave: GroupingSave,
  RecurNewEvent: RecurNewEvent,
  SubmitJournal: SubmitJournal,
  JournalReadOnlyCheck: JournalReadOnlyCheck,
  CashPaymentAmount: CashPaymentAmount,
  PaymentSaved: PaymentSaved,
  UB_Cycle_Test: UB_Cycle_Test,
  UB_Cycle_Mail: UB_Cycle_Mail,
  UB_Cycle_Bill: UB_Cycle_Bill,
  UB_Cycle_Itron_Import: UB_Cycle_Itron_Import,
  UB_Cycle_Itron_Export: UB_Cycle_Itron_Export,
  AuditRecordModal: AuditRecordModal,
  PracticeEventParent: PracticeEventParent,
  UBMeterExchange: UBMeterExchange,
  E311_RequiredProps: E311_RequiredProps,
  UB_Title_NewFinal: UB_Title_NewFinal,
  UB_ServicePrevRead: UB_ServicePrevRead,
  UB_Title_Submit: UB_Title_Submit,
  WG_LoadContactOrg: WG_LoadContactOrg,
  Ohio_DefaultBMPTypeStormWater: Ohio_DefaultBMPTypeStormWater,
  MySalesUserInfoSubmit: MySalesUserInfoSubmit,
  UB_AddressCheck: UB_AddressCheck,
  AM_PartConfigurationSave: AM_PartConfigurationSave,
  AM_PartConfigurationCheckAll: AM_PartConfigurationCheckAll,
  UB_CalculateRoutes: UB_CalculateRoutes,
  MV_NewCatalogRow: MV_NewCatalogRow,
  MV_PWANewCatalogRow: MV_PWANewCatalogRow,
  MV_PWRNewCatalogRow: MV_PWRNewCatalogRow,
  MV_PWVNewCatalogRow: MV_PWVNewCatalogRow,
  MV_MapSnapshot: MV_MapSnapshot,
  MV_PrintableEmail: MV_PrintableEmail,
  MV_SendEmails: MV_SendEmails,
  ADMIN_LookupDisplayOrder: ADMIN_LookupDisplayOrder,
  AM_CrewMgt: AM_CrewMgt,
  MV_SuperAdminFilter: MV_SuperAdminFilter,
  MV_SuperAdminCopyCatalog: MV_SuperAdminCopyCatalog,
  MV_GetCost: MV_GetCost,
  MV_SuperAdminNewContractor: MV_SuperAdminNewContractor,
  MV_LinkToContractorSite: MV_LinkToContractorSite,
  DBO_PrintTemplate: DBO_PrintTemplate,
  MySalesmanLibrarySelected: MySalesmanLibrarySelected,
  Ohio_CopyEvent: Ohio_CopyEvent,
  MV_DeleteCatalogItem: MV_DeleteCatalogItem,
  MV_SendTwilioCode: MV_SendTwilioCode,
  MV_GetConsumer: MV_GetConsumer,
  NE_LincolnParks_CopyTree: NE_LincolnParks_CopyTree,
  UB_CheckTotalFinalsForDate: UB_CheckTotalFinalsForDate,
  MV_NewContact: MV_NewContact,
  MV_DeleteContact: MV_DeleteContact,
  MV_DeckNewCatalogRow: MV_DeckNewCatalogRow,
  MV_DeckStainNewCatalogRow: MV_DeckStainNewCatalogRow,
  MV_FencePricingRules: MV_FencePricingRules,
  MV_DeckPricingRules: MV_DeckPricingRules,
  MV_DeckStainPricingRules: MV_DeckStainPricingRules,
  MV_PWAPricingRules: MV_PWAPricingRules,
  MV_PWRPricingRules: MV_PWRPricingRules,
  MV_PWVPricingRules: MV_PWVPricingRules,
  MV_ArcSite: MV_ArcSite,
  ReloadPage: ReloadPage,
  UB_GroupClear: UB_Admin_GroupClear,
  UB_GroupSave: UB_Admin_GroupSave,
  MV_DeleteConsumer: MV_DeleteConsumer,
  UB_SewerRebase: UB_SewerRebase,
  UB_BillingEvent: UB_BillingEvent,
  MV_RoofNewCatalogRow: MV_RoofNewCatalogRow,
  MV_RoofPricingRules: MV_RoofPricingRules,
  OH_Fund_Validation: OH_Fund_Validation,
  CopyMarkingSupport: CopyMarkingSupport,
  AM_DeleteUser: AM_DeleteUser,
  AM_TaskListFilter: AM_TaskListFilter,
  AM_TaskListFilterClear: AM_TaskListFilterClear,
  AutoListen: [
    {
      Type: 'MV_SuperAdminFilter',
      TriggerKey: 'sr_CompanyFilter',
      ApplicationId: 34
    },
    {
      Type: 'MV_MapSnapshot',
      TriggerKey: 'sr_MapSnapshot',
      ApplicationId: 30
    },
    {
      Type: 'MV_PrintableEmail',
      TriggerKey: 'sr_PrintableEmail',
      ApplicationId: 30,
    },
    {
      Type: 'MV_SendEmails',
      TriggerKey: 'sr_SendEmails',
      ApplicationId: 30,
    },
    {
      Type: 'MV_MapSnapshot',
      TriggerKey: 'sr_MapSnapshot',
      ApplicationId: 26
    },
    {
      Type: 'MySalesUserInfoSubmit',
      TriggerKey: 'sr_UserInfoSubmit',
      ApplicationId: 26
    },
    {
      Type: 'MySalesUserInfoSubmit',
      TriggerKey: 'sr_UserInfoSubmit',
      ApplicationId: 30
    },
    {
      Type: 'MV_PrintableEmail',
      TriggerKey: 'sr_PrintableEmail',
      ApplicationId: 26,
    },
    {
      Type: 'MV_SendEmails',
      TriggerKey: 'sr_SendEmails',
      ApplicationId: 26,
    },
    {
      Type: 'MV_NewCatalogRow',
      TriggerKey: 'sr_NewCatalogRow',
      ApplicationId: 32
    },
    {
      Type: 'MV_NewCatalogRow',
      TriggerKey: 'sr_NewCatalogRow',
      ApplicationId: 34
    },
    {
      Type: 'CashPaymentAmount',
      TriggerKey: 'sr_CashPaymentAmount',
      ApplicationId: 22
    },
    {
      Type: 'PaymentSaved',
      TriggerKey: 'dbo_SaveId',
      ApplicationId: 22
    },
    {
      Type: 'AuditRecordModal',
      TriggerKey: 'sr_AuditRecord',
      ApplicationId: 22
    }, {
      Type: 'UBMeterExchange',
      TriggerKey: 'dbo_SaveId',
      ApplicationId: 22
    },
    {
      Type: 'AuditRecordModal',
      TriggerKey: 'sr_AuditRecord',
      ApplicationId: 11
    },
    {
      Type: 'CashPaymentAmount',
      TriggerKey: 'sr_CashPaymentAmount',
      ApplicationId: 11
    },
    {
      Type: 'PaymentSaved',
      TriggerKey: 'dbo_SaveId',
      ApplicationId: 11
    },
    {
      Type: 'UBMeterExchange',
      TriggerKey: 'dbo_SaveId',
      ApplicationId: 11
    },
    {
      Type: 'UB_Title_NewFinal',
      TriggerKey: 'sr_Title_NewFinal',
      ApplicationId: 23
    },
    {
      Type: 'UB_Title_Submit',
      TriggerKey: 'sr_TitleSubmit',
      ApplicationId: 23
    },
    {
      Type: 'UB_CalculateRoutes',
      TriggerKey: 'sr_CalculateRoutes',
      ApplicationId: 11
    },
    {
      Type: 'ADMIN_LookupDisplayOrder',
      TriggerKey: 'sr_LookupDown',
      ApplicationId: 31
    },
    {
      Type: 'ADMIN_LookupDisplayOrder',
      TriggerKey: 'sr_LookupUp',
      ApplicationId: 31
    },
    {
      Type: 'AM_CrewMgt',
      TriggerKey: 'sr_CrewMgt',
      ApplicationId: 3
    },
    {
      Type: 'DBO_PrintTemplate',
      TriggerKey: 'sr_PrintTemplate',
      ApplicationId: 32
    },
    {
      Type: 'DBO_PrintTemplate',
      TriggerKey: 'sr_PrintTemplate',
      ApplicationId: 34
    },
    {
      Type: 'MV_PWANewCatalogRow',
      TriggerKey: 'sr_PWANewCatalogRow',
      ApplicationId: 32
    },
    {
      Type: 'MV_PWRNewCatalogRow',
      TriggerKey: 'sr_PWRNewCatalogRow',
      ApplicationId: 32
    },
    {
      Type: 'MV_PWVNewCatalogRow',
      TriggerKey: 'sr_PWVNewCatalogRow',
      ApplicationId: 32
    },
    {
      Type: 'UB_CheckTotalFinalsForDate',
      TriggerKey: 'sr_CheckTotalFinalsForDate',
      ApplicationId: 11
    },
    {
      Type: 'MV_NewContact',
      TriggerKey: 'sr_NewContact',
      ApplicationId: 32
    },
    {
      Type: 'MV_DeleteContact',
      TriggerKey: 'sr_DeleteContact',
      ApplicationId: 32
    },
    {
      Type: 'MV_DeckNewCatalogRow',
      TriggerKey: 'sr_DeckNewCatalogRow',
      ApplicationId: 32
    },
    {
      Type: 'MV_DeckStainNewCatalogRow',
      TriggerKey: 'sr_DeckStainNewCatalogRow',
      ApplicationId: 32
    },
    {
      Type: 'MV_FencePricingRules',
      TriggerKey: 'sr_FencePricingRules',
      ApplicationId: 32
    },
    {
      Type: 'MV_DeckPricingRules',
      TriggerKey: 'sr_DeckPricingRules',
      ApplicationId: 32
    },
    {
      Type: 'MV_DeckStainPricingRules',
      TriggerKey: 'sr_DeckStainPricingRules',
      ApplicationId: 32
    },
    {
      Type: 'MV_PWAPricingRules',
      TriggerKey: 'sr_PWAPricingRules',
      ApplicationId: 32
    },
    {
      Type: 'MV_PWRPricingRules',
      TriggerKey: 'sr_PWRPricingRules',
      ApplicationId: 32
    },
    {
      Type: 'MV_PWVPricingRules',
      TriggerKey: 'sr_PWVPricingRules',
      ApplicationId: 32
    },
    {
      Type: 'MV_ArcSite',
      TriggerKey: 'sr_ArcSite',
      ApplicationId: 32
    },
    {
      Type: 'UB_GroupClear',
      TriggerKey: 'sr_UBGroupClear',
      ApplicationId: 32
    },
    {
      Type: 'UB_GroupSave',
      TriggerKey: 'sr_UBGroupSave',
      ApplicationId: 32
    },
    {
      Type: 'MV_DeleteConsumer',
      TriggerKey: 'sr_DeleteConsumer',
      ApplicationId: 32
    },
    {
      Type: 'UB_SewerRebase',
      TriggerKey: 'sr_SewerRebase',
      ApplicationId: 11
    },
    {
      Type: 'UB_BillingEvent',
      TriggerKey: 'sr_BillingEvent',
      ApplicationId: 11
    },
    {
      Type: 'MV_RoofNewCatalogRow',
      TriggerKey: 'sr_RoofNewCatalogRow',
      ApplicationId: 32
    },
    {
      Type: 'MV_RoofPricingRules',
      TriggerKey: 'sr_RoofPricingRules',
      ApplicationId: 32
    },
    {
      Type: 'OH_Fund_Validation',
      TriggerKey: 'sr_OH_Fund_Validation',
      ApplicationId: 9
    },
    {
      Type: 'MV_DeleteCatalogItem',
      TriggerKey: 'sr_MV_DeleteCatalogItem',
      ApplicationId: 32
    },
    {
      Type: 'MV_DeleteCatalogItem',
      TriggerKey: 'sr_MV_DeleteCatalogItem',
      ApplicationId: 34
    },
    {
      Type: 'CopyMarkingSupport',
      TriggerKey: 'sr_CopyMarkingSupport',
      ApplicationId: 3
    },
    {
      Type: 'AM_DeleteUser',
      TriggerKey: 'sr_AM_DeleteUser',
      ApplicationId: 31,
    },
    {
      Type: 'AM_TaskListFilter',
      TriggerKey: 'sr_TaskListFilter',
      ApplicationId: 4,
    },
    {
      Type: 'AM_TaskListFilterClear',
      TriggerKey: 'sr_TaskListFilterClear',
      ApplicationId: 4,
    }
  ]
};
