import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    OriginalTasks: state.AM_OriginalTasks ? state.AM_OriginalTasks : undefined,
    Controls: state.Controls ? state.Controls : undefined,
  };
};

export class AM_TaskListFilterClear extends React.PureComponent {

  componentDidMount() {
    if(this.props.OriginalTasks) {
      // Reset Filter Controls
      const controls = this.props.Controls;
      this.props.dispatch(actions.UpdateProp({
          Key: 'Controls',
          Value: {
              ...controls,
              'Filter_TaskList_Assigned': '',
              'Filter_TaskList_Crew': '',
              'Filter_TaskList_Date': '',
              'Filter_TaskList_Priority': '',
              'Filter_TaskList_Type': '',
          }
      }))

      // Reset Task List
      this.props.dispatch(actions.UpdateProp({
          Key: 'ent_ToDoList',
          Value: this.props.OriginalTasks,
      }));

      // Close Modal
      this.props.dispatch(actions.UpdateProp({
          Key: 'blu_ModalScene',
          Value: undefined,
      }));
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(AM_TaskListFilterClear);