import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import { fromLonLat } from 'ol/proj'
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4';
import { selector } from './../../../../Stores/Reducers/reducer';
import { batch } from 'react-redux';
import MapData from './../../../Widgets/Map/v001/MapData/Metadata';
import Geolocation from 'react-native-geolocation-service';
import Scripts from '../../ScriptRunner/v001/ScriptMap';
import * as hub from './../../../../Utils/PubSub';
import * as Azure from '@azure/storage-blob';

const mapStateToProps = (state, ownProps) => {

  let selectedEntity = state.ent_Selected;

  if (state.ShowEventPicker && state.ShowEventPicker.SelectedEntity) {
    selectedEntity = state[state.ShowEventPicker.SelectedEntity];
  }

  return {
    EntityMetadata: state.met_EntityMetadata,
    Location: state.UserLocation,
    CurrentEntity: state.ent_Current,
    SelectedEntity: selectedEntity,
    Scenes: state.blu_Scene,
    ApplicationId: state.blu_ApplicationId,
    ShowEventPicker: state.ShowEventPicker,
    RefreshEM: state.RefreshEM || false,
    RefreshDropDownBuckets: state.RefreshDropDownBuckets || false,
    BucketRefreshId: state.BucketRefreshId || 0
  };
};

export class EntityMetadata extends React.PureComponent {
  initialized = false;

  constructor(props) {
    super(props);
    if (!this.props.EntityMetadata && this.props.ApplicationId) {
      this.getEM();
    }
    if (!this.props.Location) {
      this.getLoc();
    }

    MapData.getMetadata(this.props.dispatch);
    this.registerKeyListener();
    this.GetDropDownBuckets();
    this.getSaSToken();
  }

  componentDidUpdate() {
    if (this.props.ApplicationId && !this.initialized) {
      this.getEM();
      this.initialized = true;
    }
    if (this.props.ShowEventPicker) {
      this.eventPickerDialog();
    }

    if (this.props.RefreshEM) {
      this.getEM(false, true);
      this.props.dispatch(actions.UpdateProp({
        Key: 'RefreshEM',
        Value: false
      }));
    }

    if (this.props.RefreshDropDownBuckets) {
      this.GetDropDownBuckets();
    }
  }

  GetDropDownBuckets = () => {

    this.props.dispatch(actions.UpdateProp({
      Key: 'RefreshDropDownBuckets',
      Value: false
    }));

    let body = {
      ApplicationId: this.props.ApplicationId
    }

    actions.ApiRequest('Entity/GetDropDownBuckets', body, (result) => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'met_DropdownBuckets',
        Value: result ? result.Buckets : null
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'BucketRefreshId',
        Value: this.props.BucketRefreshId + 1
      }));
    });

  }

  getLoc = () => {
    this.registerProjection();
    Geolocation.getCurrentPosition((pos) => {
      this.setLocation(pos);
    });

    Geolocation.watchPosition((pos) => {
      this.setLocation(pos);
    }, () => { }, { enableHighAccuracy: true, distanceFilter: 1 });
  }

  setLocation = (result) => {
    let projLoc = fromLonLat([result.coords.longitude, result.coords.latitude], 'EPSG:102705');

    this.props.dispatch(actions.UpdateProp({
      Key: 'UserLocation',
      Value: {
        Latitude: result.coords.latitude,
        Longitude: result.coords.longitude,
        LatProj: projLoc[0],
        LongProj: projLoc[1]
      }
    }));
  }

  registerProjection = () => {
    proj4.defs('EPSG:102705', '+proj=tmerc +lat_0=40.25 +lon_0=-96.68805555555555 +k=1.000054615 +x_0=49999.99999999998 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs');
    register(proj4);
  }

  getEM = (resetEM = true, globalRefresh = false) => {
    this.initialized = true;
    if (resetEM) {
      this.updateEntityMetadata({});
    }
    let body = {
      ApplicationId: this.props.ApplicationId
    };
    actions.ApiRequest('Entity/GetEM', body, (result) => {
      batch(() => {
        if (!result || !result.EntityTypes)
          return;

        result.EntityTypes.forEach(type => {
          type.Parameters.forEach(param => {
            type[param.Key] = helpers.jsonOrStringValue(param.Value);
          });
          type.Parameters = undefined;
          type.Properties.forEach(prop => {
            prop.Parameters.forEach(propParam => {
              prop[propParam.Key] = helpers.jsonOrStringValue(propParam.Value);
            });
            prop.Parameters = undefined;
          });
        });
        console.log(result);
        this.updateEntityMetadata(result, globalRefresh);
        helpers.setLookupItems(result);

        if (result.CurrentUser) {
          this.props.dispatch(actions.UpdateProp({
            Key: 'CurrentUser',
            Value: { Id: result.CurrentUser }
          }));
        }
      });
    });
  }

  eventPickerDialog = () => {
    if (!this.props.EntityMetadata || !this.props.SelectedEntity) {
      this.cancelShowEvent();
      return;
    }

    let eventList = helpers.GetCreatableEvents(this.props.SelectedEntity.EntityTypeId, this.props.CurrentEntity);

    if (eventList.length === 0) {
      console.log('No creatable event types');
      this.cancelShowEvent();
      return;
    }

    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'ent_CreatableEvents',
        Value: eventList
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'blu_ModalScene',
        Value: { Value: 'EventPicker', Enabled: true }
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'ShowEventPicker',
        Value: false
      }));
    });
  }

  cancelShowEvent = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ShowEventPicker',
      Value: false
    }));
    return;
  }

  updateEntityMetadata = (met, globalRefresh) => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'met_EntityMetadata',
      Value: met
    }));

    if (met && met.EntityTypes) {
      hub.publish('SiteLoad', met);
    }

    if (globalRefresh) {
      setTimeout(() => {
        this.props.dispatch(actions.UpdateProp({
          Key: 'ent_RefreshProps',
          Value: {}
        }));
      }, 100);
    }
  }

  registerKeyListener = () => {
    let keysPressed = {};
    let lastKey = null;

    document.addEventListener('keydown', event => {
      keysPressed[event.key] = true;

      if (keysPressed['Alt'] && lastKey === 's' && event.key === 'q') {
        if (window.supportInfo) {
          this.props.dispatch(actions.UpdateProp({
            Key: 'sys_showDebugInfo',
            Value: true
          }));
        }
      }
      if (event.key !== 'Alt')
        lastKey = event.key;
    });

    document.addEventListener('keyup', event => {
      keysPressed[event.key] = false;
    });
  }

  getSaSToken = () => {
    if (!this.props.BlobSAS) {
      actions.ApiRequest('Document/BlobKey', {}, (cred) => {
        this.props.dispatch(actions.UpdateProp({
          Key: 'BlobSAS',
          Value: cred
        }));
      });
    }
    actions.ApiRequest('Document/BlobKeyPublic', {}, (cred) => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'BlobSASPublic',
        Value: cred
      }));
    });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(EntityMetadata);