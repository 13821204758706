import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    Tasks: state.ent_ToDoList,
    ControlValues: state.Controls ? state.Controls : undefined,
    OriginalTasks: state.AM_OriginalTasks,
  };
};

export class AM_TaskListFilter extends React.PureComponent {

  componentDidMount() {
    // Filter
    const originalTasks = this.props.OriginalTasks;
    const tasks = (originalTasks && originalTasks.length) ? originalTasks : this.props.Tasks;
    const controlValues = this.props.ControlValues;
    const newTasks = tasks.filter((e) => {
        console.log('e',e);
        let valid = false
        // Crew
        if(controlValues.Filter_TaskList_Crew !== "") {
          if(`${e.CrewEId}` === controlValues.Filter_TaskList_Crew) valid = true;
          else valid = false;
        }
        // Date
        if(controlValues.Filter_TaskList_Date !== "") {
          if(e.Date === controlValues.Filter_TaskList_Date) valid = true;
          else valid = false;
        }
        // Assigned To
        if(controlValues.Filter_TaskList_Assigned !== "") {
          if(`${e.AssignedEId}` === controlValues.Filter_TaskList_Assigned) valid = true;
          else valid = false;
        }
        // Event Type
        if(controlValues.Filter_TaskList_Type !== "") {
          if(`${e.EventTypeEntityId}` === controlValues.Filter_TaskList_Type) valid = true;
          else valid = false;
        }
        return valid
    });

    // Set New Task List
    this.props.dispatch(actions.UpdateProp({
        Key: 'ent_ToDoList',
        Value: newTasks,
    }));
    
    // If First Filter Store Original Values
    if(!originalTasks) {
      this.props.dispatch(actions.UpdateProp({
        Key: 'AM_OriginalTasks',
        Value: this.props.Tasks,
      }));
    }

    // Close Modal
    this.props.dispatch(actions.UpdateProp({
        Key: 'blu_ModalScene',
        Value: undefined,
    }));
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(AM_TaskListFilter);