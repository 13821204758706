import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../../../Stores/Actions/actions";
import * as helpers from "../../../../../Utils/Helpers";

const mapStateToProps = (state, ownProps) => {
  return {
    SelectedUser: state.ent_SelectedUser ? state.ent_SelectedUser : null,
  };
};

export class AM_DeleteUser extends React.PureComponent {
  componentDidMount() {
    this.props.dispatch(
      actions.UpdateProp({
        Key: "blu_Dialog",
        Value: {
          Title: "Delete User",
          Message: (
            <>
              <p style={{ fontSize: "1.1rem" }}>
                Are you sure you want to delete this user?
              </p>
              <div
                className="flex-btn-bar"
                style={{ paddingLeft: "10px", marginTop: "10px" }}
              >
                <div className="btn-container" style={{ flex: "0 1 140px" }}>
                  <div
                    className="btn-bar-btn polygon-btn"
                    onClick={() => this.confirmButton()}
                  >
                    <div>Yes</div>
                  </div>
                </div>
                <div className="btn-container" style={{ flex: "0 1 140px" }}>
                  <div
                    className="btn-bar-btn polygon-btn-neg"
                    onClick={() => this.cancelButton()}
                  >
                    <div>Cancel</div>
                  </div>
                </div>
              </div>
            </>
          ),
          Buttons: [],
        },
      })
    );
  }

  confirmButton = () => {
    try {
      this.props.dispatch(
        actions.UpdateProp({
          Key: "blu_Dialog",
          Value: null,
        })
      );

      const saveData = [];
      const baseUserObj = {
        Id: this.props.SelectedUser.EntityId,
        Table: "usr.Users",
        IsBaseTable: true,
        EntityTypeId: 1,
      };

      saveData.push(helpers.saveDataItem(baseUserObj, "IsDeleted", true));

      actions.ApiRequest("Save/Save", { SaveData: saveData }, (result) => {
        this.props.dispatch(
          actions.UpdateProp({
            Key: "blu_Dialog",
            Value: {
              Title: "User Deleted",
              Message: (
                <p style={{ fontSize: "1.1rem", marginTop: "20px" }}>
                  The user was successfully deleted.
                </p>
              ),
            },
          })
        );
        this.props.dispatch(
          actions.UpdateProp({
            Key: "ent_SelectedUser",
            Value: null,
          })
        );
      });
    } catch (e) {
      this.props.dispatch(
        actions.UpdateProp({
          Key: "blu_Dialog",
          Value: {
            Title: "Failed Deleting User",
            Message: (
              <p style={{ fontSize: "1.1rem", marginTop: "20px" }}>
                User deletion failed.
              </p>
            ),
          },
        })
      );
    }
  };

  cancelButton = () => {
    this.props.dispatch(
      actions.UpdateProp({
        Key: "blu_Dialog",
        Value: null,
      })
    );
  };

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(AM_DeleteUser);
