import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../Stores/Actions/actions';
import * as helpers from '../../../../Utils/Helpers';
import * as ExcelImport from './ExcelImport'
import * as GeoJsonImport from './GeoJsonImport'

const mapStateToProps = (state, ownProps) => {
  let mobile = ownProps.widget.Parameters.find(x => x.ParameterName === 'IsMobile');

  return {
    Metadata: state.met_EntityMetadata,
    IsMobile: mobile ? helpers.stringToBool(mobile.ParameterValue) : false
  };
}

export class UserInfo extends React.PureComponent {
  geoJsonImportSproc = '';

  state = {
    showDropdown: false
  }

  componentDidMount() {
    document.addEventListener('mouseup', this.onClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.onClick);
  }

  getName = () => {
    let name = '';
    let emd = this.props.Metadata;
    let user = emd.UserList.find(x => x.UserId === emd.CurrentUser);
    if (user) {
      name = user.Name;
    }

    return name;
  }

  toggleDropdown = (e) => {
    this.setState({ showDropdown: !this.state.showDropdown });
    e.stopPropagation();
    console.log(e);
  }

  onClick = (e) => {
    let menuClick = helpers.elementOrAncestorHasClass(e.target, 'dropdown-menu-container');
    let nameClick = helpers.elementOrAncestorHasClass(e.target, 'user-label');
    if (!menuClick && !nameClick) {
      this.setState({ showDropdown: false });
    }
  }

  logOut = () => {
    window._logout({
      returnTo: window.location.href,
      federated: true
    });
  }

  hasExcelImport = () => {
    let result = false;
    let em = this.props.Metadata;
    if (em && em.ConfigurationSettings) {
      let importItem = em.ConfigurationSettings.find(x => x.Key === 'ExcelImport');
      if (importItem && helpers.stringToBool(importItem.Value)) {
        result = true;
      }
    }
    return result;
  }

  hasGisPushCode = () => {
    let result = false;
    let em = this.props.Metadata;
    if (em && em.ConfigurationSettings) {
      let pushCode = em.ConfigurationSettings.find(x => x.Key === 'GisPushCode');
      if (pushCode && helpers.stringToBool(pushCode.Value)) {
        result = true;
      }
    }
    return result;
  }

  hasGeoJsonImport = () => {
    let result = false;
    let em = this.props.Metadata;
    if (em && em.ConfigurationSettings) {
      let importItem = em.ConfigurationSettings.find(x => x.Key === 'GeoJsonImport');
      if (importItem && importItem.Value && importItem.Value.trim()) {
        result = true;
        this.geoJsonImportSproc = importItem.Value.trim();
      }
    }
    return result;
  }

  excelImport = () => {
    this.setState({ showDropdown: false }, () => {
      let input = document.createElement("input");
      input.setAttribute("type", "file");
      input.click();
      input.addEventListener("change", (e) => {
        ExcelImport.fileAttached(e);
      });
    });
  }

  geoJsonImport = () => {
    this.setState({ showDropdown: false }, () => {
      let input = document.createElement("input");
      input.setAttribute("type", "file");
      input.click();
      input.addEventListener("change", (e) => {
        GeoJsonImport.fileAttached(e, this.geoJsonImportSproc);
      });
    });
  }

  gisPushCode = () => {
    let bettydb = this.props.Metadata.ConfigurationSettings.find(x => x.Key === 'AzureDatabaseName');

    if (!bettydb)
      return;

    let hashString = bettydb.Value + ',' + this.props.Metadata.CurrentUser;

    this.props.dispatch(actions.UpdateProp({
      Key: 'blu_Dialog',
      Value: {
        Title: 'Authentication Code', Message:
          <div className="push-code-container">
            <div className="push-code">
              <div>{btoa(hashString)}</div>
            </div>
          </div>
      }
    }));
  }

  hasMyFarmsRegistration = () => {
    if (!this.props.Metadata || !this.props.Metadata.EntityTypes)
      return false;

    let per = this.props.Metadata.EntityTypes.find(x => x.Label === 'MyFarmsRegistration');
    return !!per;
  }

  myFarmsRegistration = () => {
    let body = {
      Body: JSON.stringify({ Test: 'The test' })
    };
    actions.ApiRequest('DataQueue/RegisterMyFarms', body, (result) => {
      if (result && result.Response && result.Response.url) {
        let tab = window.open(result.Response.url, '_blank');
        if (tab) {
          tab.focus();
        }
      }
    });
  }

  changePassword = () => {
    this.props.dispatch(actions.UpdateProp({
      Key: 'ent_PassChange',
      Value: {
        EntityTypeId: 1
      }
    }))
    this.props.dispatch(actions.UpdateProp({
      Key: 'blu_ModalScene',
      Value: {
        Value: 'PasswordModal',
        Enabled: true
      }
    }));
  }

  render() {
    let excelImport = this.hasExcelImport();
    let geoJsonImport = this.hasGeoJsonImport();
    let gisPushCode = this.hasGisPushCode();
    let myFarmsReg = this.hasMyFarmsRegistration();
    let mySalesman = (window.location.toString().toLowerCase().includes('qualify.mysalesman.com') || window.location.toString().toLowerCase().includes('login.mysalesman.com')) ? true : false
    return (
      <div className={"user-info" + (this.props.IsMobile ? ' mobile-info' : '')}>
        {(!this.props.Metadata || !this.props.Metadata.UserList) ? null :
          <div className="info-box">
            <div className="current-user">
              <div className="user-label" onClick={this.toggleDropdown}>
                <div className="user-name">
                  {this.getName()}
                </div>
                <div className="triangle"></div>
              </div>
              {this.state.showDropdown &&
                <div className="dropdown-menu-container">
                  <div className="dropdown-wrapper">
                    <div className="dropdown-caret-wrapper">
                      <div className="dropdown-caret"></div>
                    </div>
                    <div className="dropdown-menu">
                      {excelImport && <div className="dropdown-menu-item" onClick={this.excelImport}>Excel Import</div>}
                      {geoJsonImport && <div className="dropdown-menu-item" onClick={this.geoJsonImport}>GeoJSON Import</div>}
                      {gisPushCode && <div className="dropdown-menu-item" onClick={this.gisPushCode}>Authentication Code</div>}
                      {myFarmsReg && <div className="dropdown-menu-item" onClick={this.myFarmsRegistration}>Register MyFarms User</div>}
                      {mySalesman && <div className="dropdown-menu-item" onClick={this.changePassword}>Change Password</div>}
                      <div className="dropdown-menu-item" onClick={this.logOut}>Log Out</div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>}
        {excelImport && <div id="slider-animation" className="excel-import-load"><div id="slider-animation-inside" className="excel-import-load-slider">Importing...</div></div>}
      </div>
    );
  }
}

export default connect(mapStateToProps)(UserInfo);