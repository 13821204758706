import React from 'react';
import { batch, connect } from 'react-redux';
import * as actions from '../../../../../Stores/Actions/actions';
import * as helpers from '../../../../../Utils/Helpers';
import cloneDeep from 'lodash.clonedeep';

const mapStateToProps = (state, ownProps) => {
  return {
    CurrentEntity: state.ent_Current,
    SaveQueue: state.dbo_ContactMgtSaveData,
    SelectedEntity: state.ent_Selected
  };
};

export class GetParcelImage extends React.PureComponent {
  RelTable = 'ub.AcctContact';
  SaveQueue = 'dbo_ContactMgtSaveData';

  componentDidMount() {
    if (!this.props.SelectedEntity || !this.props.CurrentEntity || this.props.SelectedEntity.SRParcelComplete)
      return;

    this.props.SelectedEntity.SRParcelComplete = true;

    let geometry = this.props.CurrentEntity.Geometry;

    if (geometry) {
      this.setParcelImage('');
      geometry = geometry.replace('POINT', '').replace('(', '').replace(')', '');
      let points = geometry.trim().split(' ');

      let path = `https://gis.lincoln.ne.gov/public/rest/services/Assessor/TaxParcels/FeatureServer/0/query?geometry=${points[0]}%2C${points[1]}&geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelIntersects&units=esriSRUnit_Foot&outFields=PHOTOPATH&returnGeometry=false&returnTrueCurves=false&returnIdsOnly=false&returnCountOnly=false&returnZ=false&returnM=false&returnDistinctValues=false&returnExtentOnly=false&featureEncoding=esriDefault&f=geojson`;

      console.log({ curEntity: this.props.CurrentEntity });

      actions.ExternalApiGet(path, (result) => {
        let src = '';

        console.log(result);
        if (result && Array.isArray(result.features) && result.features[0] && result.features[0].properties) {
          src = result.features[0].properties.PHOTOPATH;
        }
        this.setParcelImage(src);
      });
    } else {
      this.setParcelImage('');
    }
  }

  setParcelImage = (src) => {
    let assessorLink = '';

    if (src) {
      assessorLink = src.slice(src.indexOf('properties/') + 11, src.length);
      assessorLink = assessorLink.slice(0, assessorLink.indexOf('/'));
      assessorLink = 'https://orion.lancaster.ne.gov/Property-Detail/PropertyQuickRefID/' + assessorLink;
    }

    batch(() => {
      this.props.dispatch(actions.UpdateProp({
        Key: 'UB_AssessorLink',
        Value: assessorLink
      }));
      this.props.dispatch(actions.UpdateProp({
        Key: 'UB_ParcelImage',
        Value: src
      }));
    })

  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(GetParcelImage);