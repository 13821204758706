import React, { PureComponent } from 'react'
import { EmptyCheck, Checked, IndeterminateCheck, EmptyRadio, FillRadio } from './../../../../Icons/Icons';

export default class EditControls extends PureComponent {
  state = {
    snap: true
  };

  componentDidMount() {
    if (!this.props.controls.init) {
      this.setup();
    }
    this.setState({ snap: this.props.controls.isSnap ? true : false });
  }

  setup = () => {
    this.props.controls.isSnap = true;
    this.props.controls.init = true;
  }

  snapChange = (checked) => {
    this.props.controls.isSnap = checked;
    if (checked) {
      this.addSnap();
    } else {
      this.removeSnap();
    }
  }

  removeSnap = () => {
    this.setState({ snap: false });
    this.props.map.removeInteraction(this.props.controls.snap);
  }

  addSnap = () => {
    this.setState({ snap: true });
    this.props.map.addInteraction(this.props.controls.snap);
  }

  setGeoType = (e) => {
    this.props.CurrentEntity.CurrentType = e.target.value;
    this.props.updateGeoType(e.target.value);
    this.forceUpdate();
  }

  manualLatLongKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.useManualLatLong();
    }
  }

  render() {
    let curEnt = this.props.CurrentEntity;
    //Grabbing word for button to change dynamically based on which type you are working with
    let geoAddType;
    if (curEnt.GeometryType === 5) {
      geoAddType = 'Point';
    } else if (curEnt.GeometryType === 6) {
      geoAddType = 'Line';
    } else if (curEnt.GeometryType === 7) {
      geoAddType = 'Polygon';
    }
    return (
      <div className="map-edit-wrapper">
        {
          !this.props.controls.HideSnapCheckbox &&
          <div className="map-edit-control">
            <div className="edit-control-label">Snap</div>
            {this.state.snap && <div className="select-box" onClick={() => { this.snapChange(false) }}><Checked /></div>}
            {!this.state.snap && <div className="select-box" onClick={() => { this.snapChange(true) }}><EmptyCheck /></div>}
          </div>
        }
        {
          curEnt && curEnt.GeometryType === 1 &&
          <div>
            <div className="manual-lat-long-div">
              <p className="small-bold-text">Manual Coordinate Entry:</p>
              <input type="text" id="manual-lat" placeholder="Latitude" onKeyPress={this.manualLatLongKeyPress}></input>
              <input type="text" id="manual-long" placeholder="Longitude" onKeyPress={this.manualLatLongKeyPress}></input><br></br>
              <div className="button small-button Cancel default" onClick={this.props.useManualLatLong}>
                <div>Submit</div>
              </div>
            </div>
            {
              !this.props.controls.HideUseMyLocation &&
              <div className="button Cancel default" onClick={this.props.useMyLocation}>
                <div>Use My Location</div>
              </div>
            }
          </div>
        }
        {
          curEnt && curEnt.GeometryType === 4 &&
          <div className="map-edit-control">
            <div className="edit-control-label">Geometry Type</div>
            <select className="geo-picker" onChange={this.setGeoType} value={curEnt.CurrentType || ''} >
              <option value="Point">Point</option>
              <option value="LineString">Line</option>
              <option value="Polygon">Polygon</option>
            </select>
          </div>
        }
        {
          (curEnt && curEnt.GeometryType === 7 || curEnt.GeometryType === 5 || curEnt.GeometryType === 6) &&
          <div className="multimapcontrol">
            <div className="button default Cancel" onClick={this.props.addGeo}>
              <div>Add {geoAddType}</div>
            </div>
            <div className="button default Cancel" onClick={this.props.clearLast}>
              <div>Clear Last</div>
            </div>
          </div>
        }
        <div className="button Cancel default" onClick={this.props.clear}>
          <div>Clear All</div>
        </div>
      </div>
    )
  }
}
